<template>
	<layout-variant-two :autoHeight="proceedRegister">
		<template v-slot:body>
			<div class="register-page-con">
				<one-time-password
					:phone="phone"
					:redirect="redirect"
					v-if="proceedOTP"
					:congratulateUser="congratulateUser"
					:newUser="true"
				></one-time-password>
				<template v-if="!proceedOTP">
					<div class="page-phone" >
						<p class="page-phone__title heading-4">Berapakah Nomor Handphone Anda?</p>
						<p class="page-phone__subheading body-2-normal">
							Kami menghargai data privasi Anda.
						</p>
						<input
							class="page-phone__input"
							placeholder="Nomor Handphone Anda"
							v-model="phone"
							@keypress.enter="checkRegistrationFlow(phone)"
						/>
					</div>
					<!-- register questions -->
					<register-question></register-question>
					<div class="tnc-wrapper">Dengan klik "Lanjut", Anda menyetujui <a :href="tncLink" target="_blank" class="link no-underlined">Syarat dan Ketentuan</a> yang berlaku</div>
					<div class="register-progress-con">
						<base-button
							class="sd-btn-primary sd-btn-lg page__button"
							:isFullWidth="true"
							btnLabel="Continue"
							:isLoading="isLoading"
							:isDisabled="isLoading || !currentAnswer"
							@click="handleClick"
						></base-button>
					</div>
				</template>
			</div>
		</template>
	</layout-variant-two>
</template>

<script>
import LayoutVariantTwo from "@/components/layout/LayoutVariantTwo.vue";
//import BaseSlab from "../page-survey/components/BaseSlab.vue";
import RegisterQuestion from "./components/RegisterQuestion.vue";
import OneTimePassword from "@/presentation/components/OneTimePassword.vue";
import { loginService } from "@/bloc/services";
import { registerCustomer } from "@/connector/customerConnector";
import { mapGetters } from "vuex";
import utility from "@/presentation/mixins/utility";
// import moment from 'moment-timezone';

export default {
	mixins: [utility],
	components: {
		LayoutVariantTwo,
		// BaseSlab,
		RegisterQuestion,
		OneTimePassword,
	},
	props: {
		passedPhone: {
			type: String,
			default: "",
		},
		redirect: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			phone: "",
			checkRegistration: true,
			proceedOTP: false,
			proceedRegister: false,
			isLoading: false,
			congratulateUser: false,
		};
	},
	async created() {
		this.$store.commit("register/changeCurrentIndex", 0);
		this.$store.commit("register/changeCurrentChildIndex", 0);
		this.refreshMainData(true);
		this.$store.dispatch("register/populateWithStaticQuestions");
	},
	mounted() {
		if (this.passedPhone) {
			this.$refs.registerCheckPhone.phone = this.passedPhone;
			this.phone = this.passedPhone
			this.proceedRegister = true;
		} else {
			this.phone = this.$store.getters.getPassedPhone;
			this.$store.dispatch("setPassedPhone", "")
		}
	},
	methods: {
		async checkRegistrationFlow() {
			try {
				this.isLoading = true;
				this.refreshMainData(true);

				// check phone validity
				if (!this.isValidPhone(this.phone)) {
					this.showNotification(
						"alert",
						"error_outline",
						"Tolong masukkan nomor telepon yang valid."
					);
					this.isLoading = false;
					return;
				}

				const isRegistered = await loginService.isCustomerRegistered(
					this.phone
				);
				this.isLoading = false;

				if (isRegistered) {
					this.proceedOTP = true;
				}
				return !this.proceedOTP;
			} catch (error) {
				this.showNotification("alert", "error_outline", error);
				this.isLoading = false;
				return false;
			}
		},
		async handleClick() {
			let isEmailValid = await this.checkRegistrationFlow()
			if(isEmailValid) {
				if (this.currentQuestion.apiField == "email") {
					let isValidEmail = this.isValidEmail(this.currentAnswer);

					if (!isValidEmail) {
						this.showNotification(
							"alert",
							"error_outline",
							"Invalid Email."
						);

						return;
					}
				}

				let mappedAnswers = this.mappedAnswers;
				for (var ans in mappedAnswers) {
					if (!mappedAnswers[ans]) {
						let q = null;
						let msg = "Please fill in all fields!";
						let parent = this.registerQuestions.find((qs) => {
							return qs.children.filter((ch) => { return ch.apiField == ans }).length > 0
						});
						if (parent) {
							q = parent.children.find((ch) => { return ch.apiField == ans });
							if (q && q.required) {
								msg = q.placeholder;
								return this.showNotification(
									"alert",
									"error_outline",
									msg
								);
							}
						}
					}
				}
				// remove dob and gender as of now
				// let dob = mappedAnswers.dateOfBirth ? moment.tz(mappedAnswers.dateOfBirth, 'Asia/Singapore').format('YYYY-MM-DD') : null;
				// mappedAnswers.dateOfBirth = dob;
				// if (mappedAnswers.gender && !["MALE", "FEMALE"].includes(mappedAnswers.gender)) mappedAnswers.gender = "";

				// do registration
				let registrationParams = {
					...{ phone: this.phone, validateByPhone: true },
					...mappedAnswers,
				};
				this.isLoading = true;
				await this.registerCustomer(registrationParams);
				this.isLoading = false;
			}
		},
		async registerCustomer(customerParams) {
			try {
				let response = await registerCustomer(customerParams);
				if (response.success) {
					// logging in the customer
					this.proceedRegister = false;
					this.proceedOTP = true;
					this.congratulateUser = true;

					return;
				} else {
					this.showNotification(
						"alert",
						"error_outline",
						response.message.includes("You have already registered") ? 
							"Gagal memverifikasi akun Anda. Email telah terdaftar - mohon memasukkan alamat email berbeda." : 
							response.message
						
					);
				}
			} catch (error) {
				this.showNotification(
					"alert",
					"error_outline",
					"Network Error"
				);
			}
		},
	},
	computed: {
		tncLink(){
            let url = window.location.origin;
            return `${url}/#/faq`;
        },
		...mapGetters({
			currentAnswer: "register/getCurrentAnswer",
			mappedAnswers: "register/getMappedAnswers",
			registerQuestions: "register/getQuestions",
			totalIndex: "register/getQuestionsLength",
			currentIndex: "register/getCurrentIndex",
			currentChildIndex: "register/getCurrentChildIndex",
		}),
		currentQuestion() {
			if (this.registerQuestions) {
				return this.registerQuestions[this.currentIndex].children[this.currentChildIndex];
			}
			return null;
		},
	},
};
</script>

<style scoped lang="scss">
	.page-phone {
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 100%;
		max-width: 500px;
		margin: 25px auto;
		&__number {
			color: $secondary-color-40;
			margin-top: 50px;
		}
		&__title {
			color: $secondary-color-100;
		}
		&__subheading {
			color: $secondary-color-40;
			margin-top: 16px;
		}

		&__answer {
			margin-top: 24px;
		}
		&__input {
			border: none;
			height: 52px;
			width: 100%;
			background: $primary-color-10;
			border-radius: 12px;
			padding: 16px;
			margin-top: 24px;
		}
		&__button {
			margin-top: auto;
			width: 100%;
			height: 40px;
		}
	}
	.tnc-wrapper {
		width: 100%;
		padding: 24px 0;
		max-width: 500px;
		margin: 0 auto;
	}
	.register-page-con {
		padding: 24px;
		height: 100%;
		display: flex;
		flex-direction: column;
	}
	.register-progress-con {
		width: 100%;
		max-width: 500px;
		margin: 0 auto;
		margin-top: auto;
	}

	.page__button {
		margin-top: 16px;
		font-weight: bold;
	}
	.img-as-bground {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		pointer-events: none;
		object-fit: cover;
		height: 100%;
		z-index: -1;
		margin: auto;
		width: auto;
		height: 100%;
		object-fit: cover;
	}
	@media (min-width: 672px) {
		.register-page-con {
			padding: 24px 0 !important;
			width: 100%;
			max-width: 800px;
			margin: auto;
		}
		.img-as-bground {
			width: 100% !important;
			height: 100%;
			object-fit: contain;
		}
	}
</style>
